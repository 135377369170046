v
<!--
 * @Author: wangwei
 * @Date: 2020-12-28 16:06:28
 * @LastEditTime: 2023-01-17 20:11:04
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/views/member/order/List.vue
-->
<template>
    <div>
        <div class="sld_order_list">
            <MemberTitle :memberTitle="L['账期结算']"></MemberTitle>
            <div class="container">
                <h3>{{ L["账期结算"] }}</h3>
                <div class="sld_order_nav">
                    <div class="headerSearch">
                        <el-form ref="ruleFormRef" :model="param" :inline="true" class="demo-form-inline">
                            <el-form-item label="订单编号" prop="baseCode">
                                <el-input v-model="param.baseCode" placeholder="请输入订单编号" style="width: 260px" />
                            </el-form-item>

                            <!--<div style="width: 100%"></div>-->

                            <el-form-item label="下单时间" prop="orderStartDate">
                                <el-date-picker
                                    v-model="param.orderStartDate"
                                    type="date"
                                    placeholder="开始时间"
                                    style="width: 190px"
                                    format="YYYY-MM-DD"
                                    value-format="YYYY-MM-DD"
                                />
                            </el-form-item>
                            <span>-</span>
                            <el-form-item prop="orderEndDate">
                                <el-date-picker
                                    v-model="param.orderEndDate"
                                    placeholder="结束时间"
                                    style="width: 190px"
                                    prop="orderEndDate"
                                    format="YYYY-MM-DD"
                                    value-format="YYYY-MM-DD"
                                />
                            </el-form-item>
                            <el-form-item label="订单状态" prop="accountPeriodState" v-if="stateData.data.length > 0">
                                <el-select v-model="param.accountPeriodState" placeholder="" style="width: 260px">
                                    <el-option
                                        v-for="item in stateData.data"
                                        :key="item.state"
                                        :label="item.name"
                                        :value="item.state"
                                    />
                                </el-select>
                            </el-form-item>
                            <div class="searchBtn">
                                <div class="button" @click="onSubmit">搜索</div>
                                <el-button @click="resetForm(ruleFormRef)">重置</el-button>
                            </div>
                        </el-form>
                    </div>
                    <div></div>
                </div>
                <div class="sld_List">
                    <div class="tabsBox">
                        <div class="tabsDiv" v-if="tableData.accountPeriodTitles.length !== 0">
                            <el-scrollbar>
                                <div
                                    :class="tableData.accountPeriodTitles.length >= 1 ? 'content2' : 'content'"
                                    v-show="tableData.accountPeriodTitles"
                                >
                                    <div
                                        v-for="(item, index) in tableData.accountPeriodTitles"
                                        :key="index"
                                        :class="borderFlag == index ? 'btnActive' : 'tabs'"
                                        @click="showBorderFlag(index, 1, item.periodCode)"
                                    >
                                        <p class="MonthTitle">{{ item.month }}月账单</p>
                                        <p>({{ item.periodTime }})</p>
                                    </div>
                                </div>
                            </el-scrollbar>

                        </div>

                        <div
                            :class="borderFlag == 'unrecordedDeposit' ? 'btnActive1' : 'tabs1'"
                            @click="showBorderFlag('unrecordedDeposit', 2, '')"
                        >
                            <p class="MonthTitle" style="margin-top: 5px">未入账</p>
                        </div>
                        <div class="blank"></div>
                    </div>
                    <div
                        style="margin-bottom: 20px; font-size: 17px"
                        v-if="
                            tableData.accountPeriodTopResp.money !== null &&
                            tableData.accountPeriodTopResp.money !== 'null' &&
                            tableData.accountPeriodTopResp.money
                        "
                    >
                        {{ tableData.accountPeriodTopResp.year }}年{{
                            tableData.accountPeriodTopResp.month
                        }}月账单剩余未付：￥{{ tableData.accountPeriodTopResp.money }}
                    </div>
                    <div class="AfterSaleDiv" v-if="AfterSalePageData.total != 0">
                        <div v-for="items in AfterSaleList.data" :key="items" class="AfterSaleList">
                            <div class="AfterSaleList_colum">
                                <div>
                                    <span style="color: #5f5f60; font-weight: bold; margin-right: 10px">售后单号:</span
                                    ><span>{{ items.afterOrderCode }}</span>
                                </div>
                                <div>
                                    <span style="color: #5f5f60; font-weight: bold; margin-right: 10px">订单编号:</span
                                    ><span>{{ items.memberOrderCode }}</span>
                                </div>
                                <div>
                                    <span style="color: #5f5f60; font-weight: bold; margin-right: 10px">付款金额:</span
                                    ><span>-{{ items.returnMoney }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="AfterSaleDiv_topDiv">
                            <div style="display: flex; align-items: flex-end; margin-right: 10px">
                                共<span style="font-size: 14px; padding: 0 5px">{{ AfterSalePageData.total }}</span
                                >条
                            </div>
                            <div>
                                <p v-if="AfterSaleList.data1.length > 1 && !isDown" @click="unfold()">
                                    <span style="color: #0e3177; margin-right: 3px">展开</span
                                    ><img :src="downImg" alt="" />
                                </p>
                                <p v-if="AfterSaleList.data1.length > 1 && isDown" @click="unfold()">
                                    <span style="color: #0e3177; margin-right: 3px">收起</span
                                    ><img :src="upImg" alt="" />
                                </p>
                            </div>
                        </div>
                        <el-pagination
                            v-if="
                                (AfterSaleList.data1.length >= 5 && isDown) ||
                                (AfterSalePageData.pageNum > 1 && AfterSaleList.data1.length == 1)
                            "
                            v-model:current-page="AfterSalePageData.pageNum"
                            v-model:page-size="AfterSalePageData.pageSize"
                            :page-sizes="[5, 10, 15, 20]"
                            layout="sizes, prev, pager, next"
                            :total="AfterSalePageData.total"
                            @size-change="handleSizeChange2"
                            @current-change="handleCurrentChange2"
                        />
                    </div>
                    <el-table
                        :data="tableData.dataPage.records"
                        border
                        max-width="250"
                        header-cell-style="background:#FAFAFAFF;"
                    >
                        <el-table-column prop="baseCode" label="订单编号" width="180" align="center" />
                        <el-table-column prop="sellName" label="卖方" width="100" align="center"> </el-table-column>

                        <el-table-column prop="orderAmount" label="订单金额" max-height="60" align="center" />
                        <el-table-column prop="placeOrderTime" label="下单时间" align="center" />
                        <el-table-column prop="finishTime" label="收货时间" align="center" />
                        <el-table-column prop="remark" label="备注" align="center" />
                        <el-table-column prop="orderState" label="状态" align="center">
                            <template #default="scope">
                                <el-tag
                                    v-if="scope.row.type == 1 && scope.row.orderState == 0"
                                    @click="handleDelete(scope.$index, scope.row)"
                                    class="ml-2"
                                    effect="light"
                                >
                                    已取消
                                </el-tag>
                                <el-tag
                                    v-if="scope.row.type == 1 && scope.row.orderState == 1"
                                    @click="handleDelete(scope.$index, scope.row)"
                                    type="warning"
                                    class="ml-2"
                                    effect="light"
                                >
                                    待审核
                                </el-tag>

                                <el-tag
                                    v-if="scope.row.type == 1 && scope.row.orderState == 10"
                                    @click="handleDelete(scope.$index, scope.row)"
                                    type="success"
                                    class="ml-2"
                                    effect="light"
                                >
                                    待付款
                                </el-tag>
                                <el-tag
                                    v-if="scope.row.type == 1 && scope.row.orderState == 30"
                                    @click="handleDelete(scope.$index, scope.row)"
                                    type="success"
                                    class="ml-2"
                                    effect="light"
                                >
                                    待收货
                                </el-tag>
                                <el-tag
                                    v-if="scope.row.type == 1 && scope.row.orderState == 20"
                                    @click="handleDelete(scope.$index, scope.row)"
                                    type="info"
                                    class="ml-2"
                                    effect="plain"
                                >
                                    待发货
                                </el-tag>
                                <el-tag
                                    v-if="scope.row.type == 1 && scope.row.orderState == 40"
                                    @click="handleDelete(scope.$index, scope.row)"
                                    type="danger"
                                    class="ml-2"
                                    effect="plain"
                                >
                                    已完成
                                </el-tag>
                                <el-tag
                                    v-if="scope.row.type == 1 && scope.row.orderState == 50"
                                    @click="handleDelete(scope.$index, scope.row)"
                                    type="info"
                                    class="ml-2"
                                    effect="plain"
                                >
                                    已关闭
                                </el-tag>
                                <el-tag
                                    v-if="scope.row.type == 2 && scope.row.orderState == 1"
                                    @click="handleDelete(scope.$index, scope.row)"
                                    type="danger"
                                    class="ml-2"
                                    effect="plain"
                                >
                                    退款成功
                                </el-tag>
                                <el-tag
                                    v-if="scope.row.type == 2 && scope.row.orderState == 2"
                                    @click="handleDelete(scope.$index, scope.row)"
                                    type="danger"
                                    class="ml-2"
                                    effect="plain"
                                >
                                    退款失败
                                </el-tag>
                                <el-tag
                                    v-if="scope.row.type == 2 && scope.row.orderState == 0"
                                    @click="handleDelete(scope.$index, scope.row)"
                                    type="warning"
                                    class="ml-2"
                                    effect="plain"
                                >
                                    退款中
                                </el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="address" label="操作" align="center">
                            <template #default="scope">
                                <p class="look" type="primary" size="small" @click="toDetail(scope.row)" link>
                                    查看详情
                                </p>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        style="margin-top: 10px"
                        :vi-if="param.total != 0"
                        v-model:current-page="param.pageNum"
                        v-model:page-size="param.pageSize"
                        :page-sizes="[10, 20, 30, 40]"
                        :small="small"
                        :disabled="disabled"
                        :background="background"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="param.total"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    />
                    <div class="moneyList" v-if="borderFlag !== 'unrecordedDeposit'">
                        <!--<p><span style="color: red; font-size: 14px">合计：</span>￥{{
                    tableData.accountPeriodMain.shouldAccount
                  }}</p>-->
                        <div style="margin-top: 30px; line-height: 1.3">
                            <p>
                                <span class="fontStyle">本月应付金额：</span>￥{{
                                    tableData.accountPeriodMain.shouldAccount
                                }}
                            </p>
                            <p>
                                <span class="fontStyle">本月实付金额：</span>￥{{
                                    tableData.accountPeriodMain.realAccount
                                }}
                            </p>
                            <p class="yellow">本期账单未支付的金额自动转入下期账单</p>
                        </div>
                        <div class="paymentButton" @click="toPay(ruleFormRef)">
                            <!--v-if="tableData.accountPeriodTitles.length!==0 "-->
                            立即支付
                        </div>
                    </div>
                    <div class="moneyList" v-if="borderFlag == 'unrecordedDeposit'">
                        <!--<p style="margin-bottom: 20px">
                <span style="font-size: 14px">{{ Month }}月账单剩未末付:</span>
              </p>-->
                        <p>
                            <span style="color: red; font-size: 14px">合计：</span>￥{{
                                tableData.accountPeriodMain.shouldAccount
                            }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive, getCurrentInstance, ref, onMounted } from "vue";
import { ElInput, ElMessage, ElMessageBox, ElTimeline, ElTimelineItem } from "element-plus";
// import { Search } from '@element-plus/icons-vue'
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { apiUrl } from "../../../utils/config";
import axios from "axios";
import SldCommonEmpty from "../../../components/SldCommonEmpty";
import MemberTitle from "../../../components/MemberTitle";
import download from "downloadjs";
import { platform } from "@/utils/config";

export default {
    name: "order-list",
    components: {
        ElInput,
        ElTimeline,
        ElTimelineItem,
        SldCommonEmpty,
        MemberTitle
    },
    setup() {
        const max = ref(0);
        const value = ref(0);
        const innerRef = ref();
        const scrollbarRef = ref();
        // const balance = require("../../assets/buy/balance.png");
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        const memberInfo = reactive({ data: store.state.memberInfo });
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const AfterSaleList = reactive({
            data: [],
            data1: []
        });
        const ruleFormRef = ref();
        const borderFlag = ref(0);
        const showFlag = ref(true);
        const isDown = ref(false);
        const orderSn = ref(""); //订单id

        const tableData = reactive({
            dataPage: [],
            accountPeriodTopResp: {},
            accountPeriodMain: [],
            accountPeriodTitles: []
        });
        const Month = ref("");
        const stateData = reactive({ data: [] });
        const param = reactive({
            //memberId: store.state.memberInfo.memberId,
            baseCode: "",
            accountPeriodState: "",
            periodCode: "",
            orderStartDate: "",
            orderEndDate: "",
            pageNum: 1,
            pageSize: 10,
            total: 0,
            type: "1"
        });

        const pageData = reactive({
            pageNum: 1,
            pageSize: 10,
            total: 0
        });
        const AfterSalePageData = reactive({
            pageNum: 1,
            pageSize: 5,
            total: 0
        });
        const onSubmit = () => {
            param.pageNum = 10;
            param.pageNum = 1;
            getOrderList();
            console.log("submit!");
        };
        const toDetail = (item) => {
            if (item.type == 2) {
                router.push({
                    path: "/member/order/refund/detail",
                    query: {
                        orderId: item.baseCode,
                        orderState: item.orderState
                    }
                });
            } else {
                router.push({
                    path: "/member/order/detail",
                    query: {
                        orderSn: item.baseCode
                    }
                });
            }
        };
        const resetForm = (formEl) => {
            if (!formEl) return;
            formEl.resetFields();
            param.pageNum = 1;
            getOrderList();

            //param.resetFields()
            //param.planCode= "",
            // param. source= "",
            // param. prayName= "",
            // param. startDate= "",
            // param. endDate= "",
            //  param.resource= "",
            //  param.state= "",
            //getOrderList()
        };

        const getOrderList = () => {
            tableData.dataPage = [];
            tableData.accountPeriodTopResp = [];
            tableData.accountPeriodMain = [];
            //tableData.accountPeriodTitles = [];
            console.log(param);
            if (tableData.accountPeriodTitles.length == 0) {
                param.type = 2;
                borderFlag.value = "unrecordedDeposit";
            }
            proxy.$post("api/shopp/accountPeriod/queryAccountPeriodPay", param, "json").then((res) => {
                //原接口：v3/business/front/cart/cartList
                if (res.code == 200) {
                    tableData.dataPage = res.data.dataPage;
                    tableData.accountPeriodTopResp = res.data.accountPeriodTopResp;
                    tableData.accountPeriodMain = res.data.accountPeriodMain;
                    tableData.accountPeriodTitles = res.data.accountPeriodTitles;

                    if (res.data.dataPage.records && res.data.dataPage.records.length !== 0) {
                        console.log(77777);
                        param.pageSize = res.data.dataPage.pageSize;
                        param.pageNum = res.data.dataPage.pageNum;
                        param.total = res.data.dataPage.total;
                        console.log(param.total);
                    }
                    if (res.data.accountPeriodTitles.length == 0 && res.data.dataPage.records.length == 0) {
                        borderFlag.value = "unrecordedDeposit";
                        param.type == 2;
                        //getOrderList()
                    }
                    console.log(1111);
                    getAfterSaleList(param.type);
                    //cartLen.value = res.data.count ? res.data.count : 0
                } else {
                    console.log(88888);
                    ElMessage.error(res.msg);
                }
            });
        };
        const getMonth = () => {
            //获取当前月份
            var today = new Date();
            var currentDay = today.getDate();

            // 如果当前日期没有超过26号，则显示上一个月
            if (currentDay <= 26) {
                var lastMonth = new Date();
                lastMonth.setMonth(lastMonth.getMonth() - 1);

                Month.value = lastMonth.getMonth() + 1;
            } else {
                console.log(today.getMonth() + 1);
                Month.value = today.getMonth() + 1;
            }
        };
        const handleSizeChange = (val) => {
            param.pageSize = val;
            getOrderList();
            console.log(`${val} items per page`);
        };
        const handleCurrentChange = (val) => {
            param.pageNum = val;
            getOrderList();
            console.log(`current page: ${val}`);
        };
        const showBorderFlag = (index, type, periodCode) => {
            console.log(periodCode, 46589);
            console.log(type, 465891);
            param.pageSize = 10;
            param.pageNum = 1;
            borderFlag.value = index;

            (param.periodCode = periodCode), (param.type = type);
            //showFlag = true;
            getOrderList();
            getAfterSaleList(borderFlag.value=="unrecordedDeposit"?2:1);
        };
        const getOrderStateData = () => {
            proxy.$get("api/shopp/accountPeriod/queryOrderState").then((res) => {
                //原接口：v3/business/front/cart/cartList
                if (res.code == 200) {
                    console.log(res.data, 22222222);
                    stateData.data = res.data;
                    //cartLen.value = res.data.count ? res.data.count : 0
                }
            });
        };
        const settlement = (periodCode) => {
            let arr = {
                periodCode: periodCode
            };
            proxy.$post("api/shopp/accountPeriod/apply", arr).then((res) => {
                //原接口：v3/business/front/cart/cartList
                if (res.code == 200) {
                    ElMessage({
                        message: res.msg,
                        type: "success"
                    });
                    getOrderList();
                    //cartLen.value = res.data.count ? res.data.count : 0
                } else {
                    ElMessage.error(res.msg);
                }
            });
        };
        const toPay = () => {
            console.log(tableData.accountPeriodTopResp.money);

            if (
                (tableData.dataPage.records.length !== 0 && tableData.dataPage.records != []) ||
                tableData.accountPeriodTopResp.money
            ) {
                if (tableData.accountPeriodMain.paymentWay == "ACCEPT") {
                    ElMessage.error("订单已选择承兑支付,不可更改，请勿重复支付");
                } else {
                    if (
                        tableData.accountPeriodMain.shouldAccount &&
                        Number(tableData.accountPeriodMain.shouldAccount) >= 0
                    ) {
                        let paySn = tableData.accountPeriodTitles[borderFlag.value].periodCode;
                        router.push({
                            path: "/buy/pay",
                            query: {
                                paySn,
                                payFrom: 2
                            }
                        });
                    } else {
                        ElMessage.error("付款金额为负数，请与运营人员线下联系");
                    }
                }
            } else {
                ElMessage.error("没有可支付的订单");
            }
        };
        const inputSlider = (value) => {
            //scrollbarRef.value!.setScrollTop(value)
        };
        const scroll = ({ scrollTop }) => {
            value.value = scrollTop;
        };
        const formatTooltip = (value) => {
            return `${value} px`;
        };
        const handleSizeChange2 = (val) => {
            AfterSalePageData.pageSize = val;

            getAfterSaleList(borderFlag.value === "unrecordedDeposit" ? 2 : 1);
            console.log(`${val} items per page`);
        };
        const handleCurrentChange2 = (val) => {
            AfterSalePageData.pageNum = val;
            isDown.value = false;
            getAfterSaleList(borderFlag.value === "unrecordedDeposit" ? 2 : 1);
            console.log(`current page: ${val}`);
        };
        const unfold = () => {
            isDown.value = !isDown.value;
            if (isDown.value) {
                AfterSaleList.data = AfterSaleList.data1;
            } else {
                console.log(AfterSaleList.data1);
                AfterSaleList.data = AfterSaleList.data1.slice(0, 1);
            }
        };
        const getAfterSaleList = (type) => {
            // let type=""
            //if (borderFlag.value === "unrecordedDeposit") {
            //  type= 2;
            //} else{
            //   type = 1;
            //}

            proxy
                .$get("api/shopp/accountPeriod/queryAccountPeriodReturnOrder", {
                    ...AfterSalePageData,
                    type,
                    periodCode: tableData.accountPeriodMain.periodCode
                })
                .then((res) => {
                    if (res.code == 200) {
                        AfterSaleList.data1 = res.data.records;
                        AfterSaleList.data = AfterSaleList.data1.slice(0, 1);
                        AfterSalePageData.pageNum = res.data.pageNum;
                        AfterSalePageData.pageSize = res.data.pageSize;
                        AfterSalePageData.total = res.data.total;
                        AfterSalePageData.pageNum = res.data.pageNum;
                        //cartLen.value = res.data.count ? res.data.count : 0
                    } else {
                        ElMessage.error(res.msg);
                    }
                });
        };
        onMounted(() => {
            //  if (route.query.orderState) {
            //    current_state.value = route.query.orderState;
            //  } else {
            //    current_state.value = "";
            //  }
            //  if (route.query.evaluateState) {
            //    evaluate_state.value = route.query.evaluateState;
            //  } else {
            //    evaluate_state.value = "";
            //  }
            //  getTime();

            getOrderList();
            getMonth();
            getOrderStateData();
        });

        //router.beforeEach((to, from, next) => {
        //  //  if (to.query.orderState) {
        //  //    current_state.value = to.query.orderState;
        //  //  } else {
        //  //    current_state.value = "";
        //  //  }
        //  //  if (to.query.evaluateState) {
        //  //    evaluate_state.value = to.query.evaluateState;
        //  //  } else {
        //  //    evaluate_state.value = "";
        //  //  }
        //  if (to.path == "/member/procurement/procurementPlanList") {
        //  }
        //  next();
        //});
        return {
            L,
            getOrderList,
            onSubmit,
            Month,
            param,
            tableData,
            toDetail,
            resetForm,
            ruleFormRef,
            pageData,
            handleSizeChange,
            handleCurrentChange,
            borderFlag,
            showFlag,
            showBorderFlag,
            toPay,
            getOrderStateData,
            stateData,
            settlement,
            scroll,
            inputSlider,
            formatTooltip,
            max,
            value,
            innerRef,
            scrollbarRef,
            AfterSalePageData,
            handleSizeChange2,
            handleCurrentChange2,
            unfold,
            AfterSaleList,
            isDown
        };
    }
};
</script>
<style lang="scss">
@import "../../../style/orderList.scss";
.demo-tabs > .el-tabs__content {
    padding: 32px;
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
}
.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    color: #0e3177;
    background-color: rgb(237, 235, 235);
    border-right-color: var(--el-border-color-base);
    border-left-color: var(--el-border-color-base);
    border-top: 4px solid #0e3177;
}
.demo-tabs .custom-tabs-label {
    display: flex;
    height: 40px;
}
.sld_order_list .container {
    width: 1018px;
}
.el-pager li.active {
    color: $colorMain;
    cursor: default;
    font-size: 16px;
}
.look {
    color: rgb(74, 122, 211);
    cursor: pointer;
}
.el-pager li:hover {
    color: $colorMain;
}
.el-select-dropdown__item.selected {
    color: $colorMain;
}

.sld_order_list {
    color: #000;
    .sld_List {
        color: #000;
    }
    ::v-deep .el-dialog__header {
        background-color: $colorMain;
        padding: 11px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .el-dialog__title {
            color: #fff;
            font-size: 14px;
        }
        ::v-deep .el-dialog__headerbtn {
            top: auto;
        }
        ::v-deep .el-dialog__headerbtn .el-dialog__close {
            color: #fff !important;
            font-size: 18px;
        }
    }
    ::v-deep .el-dialog {
        overflow: hidden;
        border-radius: 4px;
    }
    ::v-deep .el-dialog__footer {
        display: flex;
        // justify-content: center;
        align-items: center;
    }
    .aft_btn:hover {
        background-color: rgba($color: #f30213, $alpha: 0.2);
        color: #f30213;
    }
    .aft_btn:focus {
        background-color: #fff;
        color: #333333;
        border-color: #dcdfe6;
    }
    .dia_btn {
        .el-button {
            width: 134px;
            height: 36px;
        }
    }
    .downLoad_contract {
        width: 150px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border: 1px solid #e8e8f1;
        border-radius: 5px;
        cursor: pointer;
    }
    .downLoad_contract:hover {
        border: 1px solid $colorMain;
    }
}
.container {
    .bootom-footer {
        text-align: right;
    }
    .bootom-footer button:first-child {
        margin-right: 10px;
    }
    // .el-input {
    //   width: 300px;
    // }
    .unit {
        font-size: 18px;
        margin-left: 10px;
    }
}
.demo-form-inline .el-input {
    --el-input-width: 220px;
}
.el-table thead {
    color: #5f5f60;
}
.el-table {
    color: #2b2b2c;
}
.sld_order_button {
    margin-bottom: 10px;
}
.el-radio {
    margin-right: 0 !important;
}
.button {
    background: #0e3177;
    cursor: pointer;
    width: 70px;
    height: 40px;
    border-radius: 4px;
    text-align: center;
    color: #fff;
    /*margin-left: 10px;*/
    line-height: 40px;
    font-size: 14px;
    margin-right: 10px;
}
.headerSearch {
    position: relative;
    .el-form--inline .el-form-item {
        margin-left: 10px;
    }
}
.searchBtn {
    cursor: pointer;
    display: flex;
    width: 200px;
    position: absolute;
    top: 0%;
    left: 85%;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
    /*background: #03499e;*/
}
.tabsBox {
    width: 100%;
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    .btnActive1 {
        border-top: 4px #0e3177 solid;
        background-color: #ffffff;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        text-align: center;
        color: #0e3177;
        cursor: pointer;
        width: 184px;
        /*line-height: 1.5;*/
        height: 56px;
        padding: 10px 10px;
        background: rgb(250, 250, 250);
        .MonthTitle {
            font-size: 18px;
            font-weight: bold;
        }
        /*line-height: 1.5;*/
    }
    .tabs1 {
        text-align: center;
        cursor: pointer;
        width: 184px;
        padding: 10px 10px;
        /*line-height: 1.5;*/
        height: 56px;
        border-bottom: 1px solid #ccc;
        .MonthTitle {
            font-size: 18px;
        }
    }
    .blank {
        width: 100%;
        border-bottom: 1px solid #ccc;
        padding: 27.5px 0px;
        margin-bottom: 2px;
    }
    .tabsDiv {
        max-width: 558px;
        white-space: nowrap; /* 防止文本换行 */
        .content {
            /*width: auto;*/
            /*font-size: 0;*/
            overflow: hidden;
            white-space: nowrap;
            display: flex;
            font-size: 15px;
            align-items: center;
            justify-content: flex-start;
            overflow-x: auto;

            .tabs {
                text-align: center;
                cursor: pointer;
                width: 186px;
                padding: 10px 10px;
                /*line-height: 1.5;*/
                height: 56px;
                border-bottom: 1px solid #ccc;
                .MonthTitle {
                    font-size: 18px;
                }
            }
            .btnActive {
                border-top: 4px #0e3177 solid;
                background-color: #ffffff;
                border-left: 1px solid #ccc;
                border-right: 1px solid #ccc;
                text-align: center;
                color: #0e3177;
                cursor: pointer;
                width: 186px;
                /*line-height: 1.5;*/
                height: 56px;
                padding: 10px 10px;
                background: rgb(250, 250, 250);
                .MonthTitle {
                    font-size: 18px;
                    font-weight: bold;
                }
                /*line-height: 1.5;*/
            }
        }
        .content2 {
            /*width: auto;*/
            /*font-size: 0;*/
            white-space: nowrap;
            display: flex;
            font-size: 15px;
            align-items: center;
            justify-content: flex-start;
            overflow-x: auto;
            .tabs {
                text-align: center;
                cursor: pointer;
                width: 186px;
                padding: 10px 10px;
                /*line-height: 1.5;*/
                height: 56px;
                border-bottom: 1px solid #ccc;
                .MonthTitle {
                    font-size: 18px;
                }
            }
            .btnActive {
                border-top: 4px #0e3177 solid;
                background-color: #ffffff;
                border-left: 1px solid #ccc;
                border-right: 1px solid #ccc;
                text-align: center;
                color: #0e3177;
                cursor: pointer;
                width: 186px;
                /*line-height: 1.5;*/
                height: 56px;
                padding: 10px 10px;
                background: rgb(250, 250, 250);
                .MonthTitle {
                    font-size: 18px;
                    font-weight: bold;
                }
                /*line-height: 1.5;*/
            }
        }
    }
}
.moneyList {
    text-align: right;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .fontStyle {
        font-size: 14px;
        color: #909090ff;
        font-family: MicrosoftYaHei;
    }
    .yellow {
        font-size: 12px;
        color: #e18f4cff;
        margin-top: 5px;
        background-color: #fff2e3ff;
        padding: 2px;
    }
    .paymentButton {
        background: #0e3177ff;
        width: 102px;
        height: 31px;
        border-radius: 4px;
        line-height: 31px;
        text-align: center;
        color: #ffff;
        font-size: 14px;
        margin-top: 16px;
        cursor: pointer;
    }
}

::-webkit-scrollbar {
    margin-top: 10px;
    width: 10px;
    background-color: #f5f5f5;
    height: 5px;
}
/* 设置滚动条滑块的样式 */
::-webkit-scrollbar-thumb {
    margin-top: 10px;
    background-color: #ccc;
    border-radius: 5px;
}
/* 设置滚动条滑块的hover状态样式 */
::-webkit-scrollbar-thumb:hover {
    background-color: #0e3177;
    margin-top: 10px;
}
* {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}
.button {
    background: #0e3177;
    width: 70px;
    height: 40px;
    border-radius: 4px;
    text-align: center;
    color: #fff;
    margin-left: 10px;
    line-height: 40px;
    font-size: 14px;
    margin-right: 10px;
}
.el-button--primary {
    --el-button-background-color: #0e3177;
    --el-button-border-color: #0e3177;
    --el-button-hover-color: #0e3177;
    --el-button-background-color: #0e3177;
    --el-button-border-color: #0e3177;
    --el-button-hover-color: #0e3177;
}
.AfterSaleDiv {
    /*position: absolute;*/
    width: 100%;
    border: 1px solid #ebeef5;
    padding: 23px;
    margin-bottom: 15px;
    /*display: flex;*/
    position: relative;
    .AfterSaleDiv_topDiv {
        top: 38px;
        right: 25px;
        position: absolute;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }
    .AfterSaleList {
        /*justify-content: space-around;*/
        font-size: 14px;
        width: 100%;
        /*width: 90%;*/
        .AfterSaleList_colum {
            display: flex;
            div {
                margin: 0 60px 0px 40px;

                line-height: 50px;
            }
        }
    }
}
</style>
